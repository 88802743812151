<template>
  <div style="padding:16px">
    <el-card>
      <div class="title">
        <h3>服务器搬迁过程统计</h3>
        <el-popover placement="right" width="400" style="margin-left:10px" trigger="hover">
          <img src="../../../../static/1.png" style="width:410px" alt="" />
          <i class="el-icon-question" style="cursor: pointer;" slot="reference"></i>
        </el-popover>
      </div>
      <el-form
        ref="queryForm"
        :model="queryParams"
        size="small"
        label-width="90px"
        @submit.native.prevent
      >
        <div class="formModel">
          <el-form-item label="一级机房" prop="idcpName">
            <el-cascader
              v-model="queryParams.idcpName"
              :options="depOptions"
              :props="{
                multiple: true,
                expandTrigger: 'hover',
                label: 'dept_name',
                value: 'dept_name',
                children: 'children',
              }"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="建单时间" prop="createdTimeRange">
            <el-date-picker
              v-model="createdTimeRange"
              type="daterange"
              class="formItem"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
              @keyup.enter.native="handleQuery"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="搬迁类型" prop="processTitle">
            <el-select
              v-model="queryParams.processTitle"
              placeholder="请选择搬迁类型"
              clearable
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.id"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="formItem btns">
            <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery"
              >搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="small" @click="handleFormRest('queryForm')"
              >重置
            </el-button>
          </div>
        </div>
      </el-form>
    </el-card>
    <!-- 搬出 -->
    <el-card style="margin-top: 15px; padding-bottom: 20px">
      <div class="tabList">
        <p
          v-for="item in moveOptions"
          :class="active == item.id ? 'active' : 'unactive'"
          :key="item.id"
          @click="getTab(item)"
        >
          {{ item.value }}
        </p>
      </div>
      
      <div class="table-list">
        <div class="table-item">
          <h3>全部</h3>
          <el-table v-loading="outloading" :data="out.all" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '全部','搬出')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>正常工单</h3>
          <el-table v-loading="outloading" :data="out.normal" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '正常','搬出')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>临近超时工单</h3>
          <el-table v-loading="outloading" :data="out.nearTimeOut" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '临近超时','搬出')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>已超时工单</h3>
          <el-table v-loading="outloading" :data="out.timeOut" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '已超时','搬出')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>尾单</h3>
          <el-table v-loading="outloading" :data="out.finalTicket" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '尾单','搬出')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <!-- 搬入 -->
    <!-- <el-card style="margin-top: 15px; padding-bottom: 20px">

      <div class="table-list">
        <div class="table-item">
          <h3>全部</h3>
          <el-table v-loading="inloading" :data="into.all" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '全部','搬入')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>正常工单</h3>
          <el-table v-loading="inloading" :data="into.normal" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '正常','搬入')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>临近超时工单</h3>
          <el-table v-loading="inloading" :data="into.nearTimeOut" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '临近超时','搬入')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>已超时工单</h3>
          <el-table v-loading="inloading" :data="into.timeOut" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '已超时','搬入')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>尾单</h3>
          <el-table v-loading="inloading" :data="into.finalTicket" border>
            <el-table-column property="idcp_name" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.idcp_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ticket_total" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '尾单','搬入')"
                  class="ticket_total"
                  :class="scope.row.idcp_name == '汇总' ? 'bold' : ''"
                  >{{ scope.row.ticket_total }}</span
                >
              </template>
            </el-table-column>
            <el-table-column property="service_total" label="台数">
              <template slot-scope="scope">
                <span :class="scope.row.idcp_name == '汇总' ? 'bold' : ''">{{
                  scope.row.service_total
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card> -->
  </div>
</template>
<script>
import { deptTree } from '@/api/monitor';
import { moveList } from '@/api/processStatistics';
export default {
  name: 'processStatistics',
  data() {
    return {
      inloading: false,
      outloading: false,
      out: {
        all: [], //全部
        finalTicket: [], //尾单
        nearTimeOut: [], //临近超时
        normal: [], //正常工单
        timeOut: [], //已超时
      },
      into: {
        all: [], //全部
        finalTicket: [], //尾单
        nearTimeOut: [], //临近超时
        normal: [], //正常工单
        timeOut: [], //已超时
      },
      queryParams: {
        processTitle: '', //搬迁类型
        move_type: '', //场景（搬入、搬出）
        statisticsType: '', //工单状态
        idcpName: [], //一级机房
        ctime_start: '', //开始时间
        ctime_end: '', //结束时间
      },
      createdTimeRange: [], //创建时间
      typeOptions: [
        //搬迁类型
        {
          id: 1,
          value: '跨城',
        },
        {
          id: 2,
          value: '内迁',
        },
      ],
      moveOptions: [
        {
          id: 1,
          value: '拔线',
          code: 'Line',
        },
        {
          id: 2,
          value: '下架',
          code: 'Updown',
        },
        {
          id: 3,
          value: '出库',
          code: 'Outin',
        },
        {
          id: 4,
          value: '到货验收',
          code: 'Outin',
        },
        {
          id: 5,
          value: '上架',
          code: 'Updown',
        },
        {
          id: 6,
          value: '接线',
          code: 'Line',
        },
      ],
      moveInOptions: [
        //搬入
        {
          id: 1,
          value: '到货验收',
          code: 'Outin',
        },
        {
          id: 2,
          value: '上架',
          code: 'Updown',
        },
        {
          id: 3,
          value: '接线',
          code: 'Line',
        },
      ],
      moveOutOptions: [
        //搬出
        {
          id: 1,
          value: '拔线',
          code: 'Line',
        },
        {
          id: 2,
          value: '下架',
          code: 'Updown',
        },
        {
          id: 3,
          value: '出库',
          code: 'Outin',
        },
      ],
      active: 1,
      activeOut: 2,
      activeIn: 2,
      depOptions: [], //一级机房数据
    };
  },
  created() {
    this.deptTree();
    // this.getList('搬入');
    // this.getList('搬出');
    this.getList('')
  },
  methods: {
    //点击工单量跳转
    filterOrder(row, timeoutStatus,scene) {
      let statisticsType = "";
      if (scene == '搬入') {
        let result = this.moveInOptions.find(obj => obj.id == this.activeIn);
        statisticsType = result.code;
      }
      if (scene == '搬出') {
        let result = this.moveOutOptions.find(obj => obj.id == this.activeOut);
        statisticsType = result.code;
      }
      let params = {
        scene: scene, //场景
        endTimeStart: '', //结单开始时间
        endTimeEnd: '', //结单结束时间
        isRemind: '', //是否催办
        ticketId: '', //工单号
        processTitle: '', //工单名称
        idcpName: [], //一级机房
        ticketStatus: '进行中', //工单状态
        personInCharge: '', //负责人
        ProcessUsers: '', //当前处理人
        instanceStatus: statisticsType,//当前流程
        timeoutStatus:
          timeoutStatus == '全部'
            ? ''
            : timeoutStatus == '正常'
            ? '未超时'
            : timeoutStatus == '正常'
            ? '未超时'
            : timeoutStatus, //超时状态
        createTimeStart: this.createdTimeRange[0], //创建开始时间
        createTimeEnd: this.createdTimeRange[1], //创建截止时间
      };
      if (row.idcp_name != '汇总') {
        this.depOptions.map((item1) => {
          item1.children.map((item2) => {
            item2.children.map((item3) => {
              if (item3.dept_name == row.idcp_name) {
                params.idcpName = [[item1.dept_name, item2.dept_name, item3.dept_name]];
                return;
              }
            });
          });
        });
      } else {
        params.idcpName = [];
      }
      sessionStorage.setItem('move_params', JSON.stringify(params));
      this.$router.push({
        path: '/appManage/monitor/serverRelocation',
      });
    },
    //点击搬出
    getTab(item) {
      this.active = item.id;
      this.getList("");
    },
    // getOutTab(item) {
    //   this.activeOut = item.id;
    //   this.getList('搬出');
    // },
    // getInTab(item) {
    //   this.activeIn = item.id;
    //   this.getList('搬入');
    // },
    //一级机房数据
    deptTree() {
      deptTree().then((res) => {
        this.depOptions = res?.data?.data;
      });
    },
    //列表
    getList(move_type) {
      
      this.inloading = true;
      this.active
      if (this.active<=3){
        move_type = '搬出'
      }
      else{
        move_type = '搬入'
      }
      
      let params = JSON.stringify(this.queryParams);
      let paramsNew = JSON.parse(params);
      paramsNew.idcpName = this.queryParams.idcpName.map((row) => row.at(-1)); //一级机房
      if (this.createdTimeRange.length) {
        paramsNew.ctime_start = `${this.createdTimeRange[0]} 00:00:00`; //开始时间
        paramsNew.ctime_end = `${this.createdTimeRange[1]} 23:59:59`; //截止时间
      }
      paramsNew.move_type = move_type;

      let result = this.moveOptions.find(obj => obj.id == this.active);
      paramsNew.statisticsType = result.code;

      moveList(paramsNew)
        .then((res) => {
          //全部
          this.out.all = res.data.data.all.items;
          this.out.all.push({
            idcp_name: '汇总',
            service_total: res.data.data.all.service_total,
            ticket_total: res.data.data.all.ticket_total,
          });
          //尾单
          this.out.finalTicket = res.data.data.finalTicket.items;
          this.out.finalTicket.push({
            idcp_name: '汇总',
            service_total: res.data.data.finalTicket.service_total,
            ticket_total: res.data.data.finalTicket.ticket_total,
          });
          //临近超时
          this.out.nearTimeOut = res.data.data.nearTimeOut.items;
          this.out.nearTimeOut.push({
            idcp_name: '汇总',
            service_total: res.data.data.nearTimeOut.service_total,
            ticket_total: res.data.data.nearTimeOut.ticket_total,
          });
          //正常
          this.out.normal = res.data.data.normal.items;
          this.out.normal.push({
            idcp_name: '汇总',
            service_total: res.data.data.normal.service_total,
            ticket_total: res.data.data.normal.ticket_total,
          });
          // 已超时
          this.out.timeOut = res.data.data.timeOut.items;
          this.out.timeOut.push({
            idcp_name: '汇总',
            service_total: res.data.data.timeOut.service_total,
            ticket_total: res.data.data.timeOut.ticket_total,
          });
          this.outloading = false;
        })
        .catch(() => {
          this.inloading = false;
          this.outloading = false;
        });
    },
    // getList(move_type) {
    //   if (move_type == '搬入') {
    //     this.inloading = true;
    //   }
    //   if (move_type == '搬出') {
    //     this.outloading = true;
    //   }
      
    //   let params = JSON.stringify(this.queryParams);
    //   let paramsNew = JSON.parse(params);
    //   paramsNew.idcpName = this.queryParams.idcpName.map((row) => row.at(-1)); //一级机房
    //   if (this.createdTimeRange.length) {
    //     paramsNew.ctime_start = `${this.createdTimeRange[0]} 00:00:00`; //开始时间
    //     paramsNew.ctime_end = `${this.createdTimeRange[1]} 23:59:59`; //截止时间
    //   }
    //   paramsNew.move_type = move_type;
    //   if (move_type == '搬入') {
    //     let result = this.moveInOptions.find(obj => obj.id == this.activeIn);
    //     paramsNew.statisticsType = result.code;
    //   }
    //   if (move_type == '搬出') {
    //     let result = this.moveOutOptions.find(obj => obj.id == this.activeOut);
    //     paramsNew.statisticsType = result.code;
    //   }
    //   moveList(paramsNew)
    //     .then((res) => {
    //       if (move_type == '搬入') {
    //         //全部
    //         this.into.all = res.data.data.all.items;
    //         this.into.all.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.all.service_total,
    //           ticket_total: res.data.data.all.ticket_total,
    //         });
    //         //尾单
    //         this.into.finalTicket = res.data.data.finalTicket.items;
    //         this.into.finalTicket.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.finalTicket.service_total,
    //           ticket_total: res.data.data.finalTicket.ticket_total,
    //         });
    //         //临近超时
    //         this.into.nearTimeOut = res.data.data.nearTimeOut.items;
    //         this.into.nearTimeOut.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.nearTimeOut.service_total,
    //           ticket_total: res.data.data.nearTimeOut.ticket_total,
    //         });
    //         //正常
    //         this.into.normal = res.data.data.normal.items;
    //         this.into.normal.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.normal.service_total,
    //           ticket_total: res.data.data.normal.ticket_total,
    //         });
    //         // 已超时
    //         this.into.timeOut = res.data.data.timeOut.items;
    //         this.into.timeOut.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.timeOut.service_total,
    //           ticket_total: res.data.data.timeOut.ticket_total,
    //         });
    //         this.inloading = false;
    //       }
    //       if (move_type == '搬出') {
    //         //全部
    //         this.out.all = res.data.data.all.items;
    //         this.out.all.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.all.service_total,
    //           ticket_total: res.data.data.all.ticket_total,
    //         });
    //         //尾单
    //         this.out.finalTicket = res.data.data.finalTicket.items;
    //         this.out.finalTicket.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.finalTicket.service_total,
    //           ticket_total: res.data.data.finalTicket.ticket_total,
    //         });
    //         //临近超时
    //         this.out.nearTimeOut = res.data.data.nearTimeOut.items;
    //         this.out.nearTimeOut.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.nearTimeOut.service_total,
    //           ticket_total: res.data.data.nearTimeOut.ticket_total,
    //         });
    //         //正常
    //         this.out.normal = res.data.data.normal.items;
    //         this.out.normal.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.normal.service_total,
    //           ticket_total: res.data.data.normal.ticket_total,
    //         });
    //         // 已超时
    //         this.out.timeOut = res.data.data.timeOut.items;
    //         this.out.timeOut.push({
    //           idcp_name: '汇总',
    //           service_total: res.data.data.timeOut.service_total,
    //           ticket_total: res.data.data.timeOut.ticket_total,
    //         });
    //         this.outloading = false;
    //       }
    //     })
    //     .catch(() => {
    //       this.inloading = false;
    //       this.outloading = false;
    //     });
    // },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList('');
    },
    // 重置
    handleFormRest(formName) {
      this.$refs[formName].resetFields();
      this.createdTimeRange = [];
      this.activeIn = 2;
      this.activeOut = 2;
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-popover {
  width: 500px !important;
}
.title {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  h3 {
    padding: 0;
  }
  i {
    font-size: 20px;
  }
}
.ticket_total {
  color: #1890ff;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 30px;
}
.bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.table-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .table-item {
    width: 45%;
    margin-right: 30px;
  }
  .table-item:last-child {
    margin-right: 0;
  }
}
.tabList {
  display: flex;
  align-items: center;
  p {
    width: 120px;
    height: 40px;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    cursor: pointer;
    font-weight: bold;
  }
  .active {
    color: #fff;
    border: 1px solid #1890ff;
    background: #1890ff;
  }
  .unactive {
    color: #666;
    border: 1px solid #eaeaea;
    background: #fff;
  }
  p:nth-child(1) {
    border-right: 0;
  }
  p:nth-child(2) {
    border-right: 0;
  }
}
.ticketId {
  color: #1890ff;
  cursor: pointer;
}
.standard-blue-link {
  color: #1890ff;
  text-decoration: none;
}
.formModel {
  display: flex;
  flex-flow: row wrap;
}
.formItem {
  // width: 175px !important;
  margin-bottom: 20px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-card__body {
  padding: 15px 20px 0 20px !important;
}
.btns {
  margin-left: 20px;
}
</style>
